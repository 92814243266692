<template>
  <div>
    <!--    <navbar/>-->


    <div>
      <h1>Coming soon...</h1>

      <h2>^_^</h2>

      <router-link to="/">Back</router-link>
    </div>
    <!--    <dx-header/>-->

    <!--    <screenshots/>-->

    <!--    <features/>-->

    <!--    <dx-footer/>-->
  </div>
</template>

<script>
// import footer from './../../components/shared/footer';
// import header from './../../components/games/blockerio/header';
// import navbar from './../../components/games/blockerio/navbar';
// import features from './../../components/games/blockerio/features';
// import screenshots from './../../components/games/blockerio/screenshots';

export default {
  metaInfo: {
    bodyAttrs: {
      class: ['landing-gradient-blue-red']
    }
  },

  name: 'Blockerio',

  components: {
    // dxHeader: header,
    // features,
    // screenshots,
    // navbar,
    // dxFooter: footer
  },

  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

<style scoped>

</style>
